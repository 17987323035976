import React, { useEffect, useState, useRef } from 'react';

const Swipelux = () => {
    const [widgetInitialized, setWidgetInitialized] = useState(false);
    const swipeluxContainerRef = useRef(null);
    useEffect(() => {
        if (!widgetInitialized && swipeluxContainerRef.current) {
            const currentUrl = window.location.href;
            // Extract the parameter value using URLSearchParams
            const url = new URL(currentUrl);
            const userWallet = url.searchParams.get('walletAddress');
            const defaultValues = {
                targetAddress: {
                    value: userWallet == null ? '' : userWallet,
                    editable: userWallet == null ? true : false
                }
            };

            const settings = {
                apiKey: '10af5dc3-a05c-4170-94ce-d0c1c83a5063',
                defaultValues: defaultValues,
                colors: {
                    main: "#001b48",
                    background: "#fff"
                },
                // you can pass here other settings
            };
            const widget = new window.SwipeluxWidget(swipeluxContainerRef.current, settings);
            widget.init();
            console.log("its here")


            // Set the widgetInitialized flag to true
            setWidgetInitialized(true);

        } else {
            console.log('Swipelux Container Element Not Found');
        }
    }, [widgetInitialized]);
    return <div ref={swipeluxContainerRef} />;
};

export default Swipelux;
